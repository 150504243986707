.services {
  padding-top: 8rem;
}

.services p.contTitle {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 600;
}

.serviceCard {
  margin-bottom: 3rem;
}

.card-list li {
  margin-bottom: 1rem;
}

.serviceCard .card-title {
  margin-bottom: 2rem;
}

@media (min-width: 320px) {
  .services .serviceCard {
    width: 90%;
  }
  .services .serviceCard {
    align-self: center;
  }
  .serviceCard {
    margin-left: auto;
    margin-right: auto;
  }
}


@media (min-width: 768px) {
  .services .serviceCard {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .services .serviceCard {
    width: 50%;
  }

  .serviceCard.rightCard {
    float: right;
  }

  .serviceCard {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
