.navbar {
  font-family: 'Tenor Sans'
}

.navbar a {
  color: black!important;
}

.navbar a:hover {
  color: gray!important;
}

.navbar-nav {
  letter-spacing: .0625rem;
  font-size: 1.4rem;
  align-content: center;
}

.nav-link {
  font-size: 1.5rem!important;
  color: #465765!important;
  font-weight: 600;
}

.nav-link:hover,
.nav-link:focus {
  color: #37434d !important
}

.navbar-brand {
  font-size: 2rem!important;
  font-weight: 600;
}

.navbar-nav:nth-child(2) .nav-link {
  font-size: 2rem!important;
  display: inline-block;
  margin-right: 1rem;
}

.navbar-nav:nth-child(2) {
  display: inline-block;
}

@media (min-width: 320px) {
  .navbar-brand {
    font-size: 1.5rem!important;
  }
}

@media (min-width: 480px) {
  .navbar-brand {
    font-size: 2rem!important;
  }
}
