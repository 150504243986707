.introText {
  font-family: 'Tenor Sans';
}

.introText button {
  text-transform: uppercase;
  margin-bottom: 1rem;
}


@media (min-width: 320px) {
  .introText p:first-child {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .introText p:nth-child(2) {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  .introText {
    width: 90%;
    margin-top: -70px;
  }

  .introText button {
    font-size: 1rem;
  }
}

@media (min-width: 414px) {
  .introText p:first-child {
    font-size: 1.7rem;
    margin-bottom: 1.5rem;
  }

  .introText p:nth-child(2) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .introText {
    width: 80%;
    margin-top: -70px;
  }

  .introText button {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .introText {
    width: 80%;
    margin-top: -150px;
  }
}

@media (min-width: 992px) {
  .introText {
    width: 60%;
  }
}

@media (min-width: 1400px) {
  .introText p:first-child {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .introText p:nth-child(2) {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .introText button {
    font-size: 2rem;
  }
}
