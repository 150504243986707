footer {
  height: auto;
  background-color: black;
  color: white;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  font-family: 'Tenor Sans'
}

footer .row {
  margin-bottom: 1.5rem;
}

footer .row:last-child {
  margin-bottom: 0rem;
}

footer a {
  margin-left: 10px;
  margin-right: 10px;
  color: white!important;
}

footer a:hover {
  color: gray!important;
}

footer p.copyright {
  color: #aaa!important;
}

@media (min-width: 320px) {
  footer a {
    font-size: 1.3rem;
  }
}


@media (min-width: 768px) {
  footer a {
    font-size: 2rem;
  }
}
