.brif-container {
  position: relative;
}

.brif {
  padding-top: 8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.brif p.contTitle {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0;
}

.brif hr {
  background-color: black;
  border-width: 0.2rem;
  width: 50%;
  margin-bottom: 2rem;
}

.brif a {
  margin-top: 2rem!important;
  margin-bottom: 2rem!important;
}

@media (min-width: 320px) {
  .brif p {
    width: 80%;
  }

  .brif hr {
    width: 80%;
  }
}


@media (min-width: 768px) {
  .brif p {
    width: 50%;
  }

  .brif hr {
    width: 50%;
  }
}
