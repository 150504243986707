@font-face{
  font-family:'Tenor Sans';
  src:url(./fonts/tenorsans.woff2) format('woff2'),
    url(./fonts/tenorsans.woff) format('woff'),
    url(./fonts/tenorsans.ttf) format('truetype');
  font-weight:400;
  font-style:normal;
}

html {
  font-family: 'Tenor Sans'!important;
  text-transform: uppercase!important;
}

container {
  text-align: center;
  background-color: black;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-family: 'Tenor Sans'
}

.center-content {
  justify-content: center;
}

.secondary-container {
  background-color: white;
  color: black;
}

html, body {
  overflow-x:hidden
}
