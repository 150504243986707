.cases {
  padding-top: 8rem;
}

.cases p.contTitle {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 600;
}

.cases hr {
  background-color: white;
  border-width: 0.5rem;
  width: 50%;
}

.panel {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
}

.scrollmagic-pin-spacer {
  margin-bottom: 300px!important;
}

.scrollmagic-pin-spacer:last-child {
  margin-bottom: 0px!important;
}

.case {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.case a {
  font-size: 3rem;
  color: white!important;
}

.case a:hover {
  color: gray!important;
}

.case img {
    width: 85%;
}

.panel span {
  position: relative;
  display: block;
  overflow: visible;
  top: 50%;
  font-size: 80px;
}
