@media (min-width: 320px) {
  :root {
    --logo1Height: 140px;
    --logo2Height: 26px;
    --logo3Height: 117px;
  }
}

@media (min-width: 768px) {
  :root {
    --logo1Height: 280px;
    --logo2Height: 53px;
    --logo3Height: 232px;
  }
}

.logo {
  display: flex;
  flex-direction: column;
}

.upPart {
  position: relative;
}

.upPart img {
  height: var(--logo1Height);
}

.midPart img {
  height:  var(--logo2Height);
  width: 100%;
}

.bottomPart img {
  width: 100%;
}

.upLogo {
  background-color: black;
  height: 0px;
  width: 100%;
  bottom: 0;
  position: absolute;
  animation: heightOut 2s;
}

@keyframes heightOut {
  from { height: var(--logo1Height)}
  to   { height: 0px}
}

.midPart {
  animation: moveFromUp 2s;
  position: absolute;
  top: var(--logo1Height);
}

@keyframes moveFromUp {
  from { top: 0px}
  to   { top: var(--logo1Height)}
}

.top {
  position: relative;
}

.bottomPart {
  position: relative;
  height: var(--logo3Height);
  margin-top: var(--logo2Height);
}

.bottom {
  position: absolute;
  bottom: 1;
}
